










const clients = [
  {
    id: "transfact",
    hostnames: ["opendash.transfact-china.com", "localhost"],
    fallbackClient: false,
    openWareHost: "opendash.transfact-china.com",
    openWareSecure: true,
    parseHost: "https://opendash.transfact-china.com/parse",
    parseAppId: "openinc",
    logoImage: null,
  },
  {
    id: "kompetenzzentrum",
    hostnames: ["opendash.kompetenzzentrum-siegen.digital"],
    fallbackClient: false,
    openWareHost: "opendash.kompetenzzentrum-siegen.digital",
    openWareSecure: true,
    parseHost: "https://users.kompetenzzentrum-siegen.digital/parse",
    parseAppId: "1234567890",
    logoImage: require("../assets/kpz.png"),
  },
  {
    id: "kuenne",
    hostnames: ["kuenne-dev.openinc.dev", "kuenne.openinc.dev"],
    fallbackClient: false,
    openWareHost: "kuenne-app.hosts.openinc.de",
    openWareSecure: true,
    parseHost: "https://kuenne-app.hosts.openinc.de/parse",
    parseAppId: "openinc",
    logoImage: require("../assets/kuenne.png"),
  },
  {
    id: "braeuer",
    hostnames: ["braeuer.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    logoImage: null,
  },
  {
    id: "miaas",
    hostnames: ["miaas.openinc.dev", "localhost"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    logoImage: require("../assets/miaas.png"),
  },
  {
    id: "demo",
    hostnames: ["opendash.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    logoImage: null,
  },
  {
    id: "localhost",
    hostnames: [/*"localhost"*/ "127.0.0.1"],
    fallbackClient: false,
    // openWareHost: "localhost:4567",
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: false,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    // parseHost: "https://kuenne-app.hosts.openinc.de/parse",
    // parseHost: "http://localhost:1337/parse",
    parseAppId: "openinc",
    logoImage: null,
  },
];

class ClientSelector {
  

  constructor() {;ClientSelector.prototype.__init.call(this);ClientSelector.prototype.__init2.call(this);ClientSelector.prototype.__init3.call(this);ClientSelector.prototype.__init4.call(this);
    this.client = clients.find((client) =>
      client.hostnames.includes(window.location.hostname)
    );

    if (!this.client) {
      this.client = clients.find((client) => client.fallbackClient);
    }
  }

  getOpenWareHost() {
    return this.client.openWareHost;
  }

  getOpenWareSecure() {
    return this.client.openWareSecure;
  }

  __init() {this.getParseHost = () => {
    return this.client.parseHost;
  }}

  __init2() {this.getParseAppId = () => {
    return this.client.parseAppId;
  }}

  __init3() {this.getLogoImage = () => {
    return this.client.logoImage;
  }}

  __init4() {this.getClientId = () => {
    return this.client.id;
  }}
}

const clientselector = new ClientSelector();
console.log("Connect to client:", clientselector.getClientId());

export default clientselector;
