const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\opendash-mono\\apps\\demo\\app\\js\\components\\OverViewComponent.tsx";import * as React from "react";



import { Avatar, Layout, List, Tooltip } from "antd";
import {
  Loading,
  useOpenDashServices,
  useSource,
  WidgetStatic,



} from "@opendash/core";
import cBlend from "./colorblend";

export default () => {
  const { DataService, SourceService } = useOpenDashServices();
  const sources = SourceService.getAll();
  const [collapsed, setCollapsed] = React.useState(true);
  const [currentSource, setCurrentSource] = useSource();

  const mapSourceToTree = (source, level, colorIndex) => {
    const children = sources
      .filter((cSource) => cSource.parent === source.id)
      .map((cSource) => mapSourceToTree(cSource, level + 1, colorIndex));
    const start = //@ts-ignore
      Highcharts.getOptions().colors[
        //@ts-ignore
        colorIndex % Highcharts.getOptions().colors.length
      ];
    let color = cBlend(
      level / 5,
      start,
      //@ts-ignore
      "#eeeeee",
      true
    );

    return {
      id: source.id,
      key: source.tag,
      label: source.name,
      level,
      color,
      icon: (
        React.createElement(Avatar, {
          size: !collapsed ? 32 : 32 /*/ Math.pow(1.3, level)*/,
          style: { color: "#fff", backgroundColor: color }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}

          , source.name
            .split(" ")
            .map((part) => part[0])
            .join("")
        )
      ),
      children: children.length > 0 ? children : null,
    };
  };
  const config = {
    icon: "fa:info",
    _sources: [],
    _items: [],
    _dimensions: [["mstHome", "tado_mobiledevice_4402558b_2070111_atHome", 0]],
  };
  const renderSourceItem = (item) => {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Tooltip, { title: item.label, placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
          , React.createElement(List.Item, {
            style: {
              cursor: "pointer",
              borderRight:
                currentSource.id === item.id ? "4px solid " + item.color : null,
            },
            onClick: () => {
              setCurrentSource(item.id);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}

            , React.createElement(List.Item.Meta, {
              style: { marginLeft: collapsed ? 20 : 10 + item.level * 20 },
              avatar: item.icon,
              title: !collapsed ? item.label : "",
              description: !collapsed ? item.key : "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            )
          )
        )
        , item.children &&
          item.children.map((child) => {
            return renderSourceItem(child);
          })
      )
    );
  };
  if (!sources || sources.length == 0) {
    return React.createElement(Loading, { message: "Loading...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} );
  }

  return (
    React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
      , React.createElement(Layout.Sider, {
        width: "30%",
        collapsible: true,
        collapsed: collapsed,
        onCollapse: (value) => setCollapsed(value),
        theme: "light", __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}

        , React.createElement(List, {
          itemLayout: "horizontal",
          dataSource: sources
            .filter((source) => !source.parent)
            .map((source, index) => mapSourceToTree(source, 0, index)),
          renderItem: renderSourceItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
        )
      )
      , React.createElement(Layout.Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
        , React.createElement(WidgetStatic, {
          // key={"state.visualisation" + "~" + "asd"}
          config: config,
          type: "opendash-widget-hc-kpi", __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        )
      )
    )
  );
};
